<template>
  <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
    <a
      slot="id"
      slot-scope="text"
      href="javascript: void(0);"
      class="btn btn-sm btn-light"
    >
      {{text}}
    </a>
    <span slot="first_name" slot-scope="record"> {{ record.first_name }}</span>
    <span slot="last_name" slot-scope="record"> {{ record.last_name }}</span>
    <span slot="phone" slot-scope="record">{{ record.phone }}</span>
    <span slot="email" slot-scope="record">{{ record.email }}</span>
    <span slot="date" slot-scope="record">{{ record.created_at | getFormattedDate }}</span>
    <span slot="balance" slot-scope="record"> {{ record.balance }}</span>
    <span slot="role" slot-scope="record"> {{ record.role }}</span>
    <span slot="action" slot-scope="record" class="d-flex flex-wrap">
      <a v-if="!isStatistics && !isManager" href="javascript: void(0);" class="btn btn-sm btn-light mr-2 my-1 d-flex align-items-center" @click="toEditPage(record)">
        <i class="fe fe-edit mr-2" />
        Изменить
      </a>
      <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2 my-1 d-flex align-items-center" @click="toOverviewPage(record)">
        <small>
          <i class="fe fe-more-horizontal mr-2" />
        </small>
        Просмотр
      </a>
      <a-popconfirm
        v-if="!isStatistics"
        title="Вы уверенны что хотите удалить?"
        ok-text="Да"
        cancel-text="Нет"
        @confirm="$emit('removeItem', record.id)"
      >
        <a v-if="!isManager" href="javascript: void(0);" class="btn btn-sm btn-light my-1 d-flex align-items-center">
          <small>
            <i class="fe fe-trash mr-2" />
          </small>
          Удалить
        </a>
      </a-popconfirm>
    </span>
  </a-table>
</template>

<script>
import users from '@/mixins/users'

export default {
  name: 'ClientsTable',

  mixins: [users],

  props: {
    list: {
      type: Array,
      default: () => {
        return {}
      },
    },
    isStatistics: {
      type: Boolean,
      default: false,
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      columns: [],
    }
  },

  created() {
    this.setColumns()
  },

  watch: {
    list: {
      handler() {
        this.setColumns()
      },
      deep: true,
    },
  },

  methods: {
    setColumns() {
      const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Имя',
          scopedSlots: { customRender: 'first_name' },
        },
        {
          title: 'Фамилия',
          dataIndex: 'last_name',
        },
        {
          title: 'Телефон',
          scopedSlots: { customRender: 'phone' },
        },
        {
          title: 'Email',
          dataIndex: 'email',
        },
        {
          title: 'Дата',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Личный счет',
          scopedSlots: { customRender: 'balance' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ]
      if (this.$route.path.includes('admins')) {
        columns.splice(5, 1, {
          title: 'Роль',
          scopedSlots: { customRender: 'role' },
        })
      }
      this.columns = columns
    },

    toEditPage(record) {
      if (this.$route.path.includes('admins')) {
        this.$router.push({ path: `/users/admins/${record.id}` })
      } else {
        this.$router.push({ path: `/users/clients/${record.id}` })
      }
    },

    toOverviewPage(record) {
      if (this.$route.path.includes('admins')) {
        this.$router.push({ path: `/users/admins/${record.id}`, query: { is_preview: 'true' } })
      } else {
        this.$router.push({ path: `/users/clients/${record.id}`, query: { is_preview: 'true' } })
      }
    },
  },
}
</script>
